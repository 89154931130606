// Generated by Framer (83eb5d8)

import { addFonts, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {apzMbNZFD: {pressed: true}};

const cycleOrder = ["apzMbNZFD"];

const serializationHash = "framer-RQ7nI"

const variantClassNames = {apzMbNZFD: "framer-v-122psj3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "apzMbNZFD", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-122psj3", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"apzMbNZFD"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"apzMbNZFD-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-8uprna"} data-border layoutDependency={layoutDependency} layoutId={"C0EZC9Dwe"} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.15)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backdropFilter: "blur(5px)", backgroundColor: "rgba(255, 255, 255, 0.1)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, WebkitBackdropFilter: "blur(5px)"}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-RQ7nI[data-border=\"true\"]::after, .framer-RQ7nI [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RQ7nI.framer-bcquku, .framer-RQ7nI .framer-bcquku { display: block; }", ".framer-RQ7nI.framer-122psj3 { cursor: pointer; height: 40px; overflow: hidden; position: relative; width: 40px; }", ".framer-RQ7nI .framer-8uprna { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; will-change: var(--framer-will-change-override, transform); }", ".framer-RQ7nI.framer-v-122psj3.pressed.framer-122psj3 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 40px); }", ".framer-RQ7nI.framer-v-122psj3.pressed .framer-8uprna { bottom: 4px; left: 4px; right: 4px; top: 4px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"P1GDGjJC9":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerOCQ5RiDb8: React.ComponentType<Props> = withCSS(Component, css, "framer-RQ7nI") as typeof Component;
export default FramerOCQ5RiDb8;

FramerOCQ5RiDb8.displayName = "Outline";

FramerOCQ5RiDb8.defaultProps = {height: 40, width: 40};

addFonts(FramerOCQ5RiDb8, [])